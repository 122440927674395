import React from "react"
import { Link, graphql } from "gatsby"
import { Container, Grid, Button } from '@material-ui/core'
import Layout from '../components/theme/layout'
import ReactMarkdown from "react-markdown"

import Masthead from "../components/strapi/structure/Masthead"
import Craftsmanship from "../components/strapi/structure/Craftsmanship"
import Outropiggy from "../components/strapi/structure/Outropiggy"

import MadeCanada from '../images/banners/made-in-canada.jpg'
import Alabaster from '../images/colours/alabaster.jpg'
import Beachwood from '../images/colours/beachwood.jpg'
import Onyx from '../images/colours/onyx.jpg'
import Opal from '../images/colours/opal.jpg'
import SierraStone from '../images/colours/sierra-stone.jpg'
import Terrazzo from '../images/colours/terrazzo.jpg'

import Bamboo from '../images/cabinetry/bamboo-enviroskirt.jpg'
import Ebony from '../images/cabinetry/ebony-enviroskirt.jpg'
import Walnut from '../images/cabinetry/walnut-enviroskirt.jpg'

import Slider from "react-slick"
import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css"

import Accordion from '@material-ui/core/Accordion'
import Tooltip from '@material-ui/core/Tooltip'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export default function ModelNumber({ data }) {

    const post = data.strapiModels

    const settings = {
        infinite: true,
        arrows: false,
        dots: true,
        fade: true,
        autoplay: true,
        autoplaySpeed: 9000,
        speed: 300,
        slidesToShow: 1,
        slide: 'div',
        slidesToScroll: 1
      };

    return (
        <Layout>

                <Masthead key={post.slug}
                    mhtitle={post.slug}
                    mhsubtitle={post.m_header.mh_subtitle}
                    model={true}
                />
                
                {/* Start Slider */}
                <Container className="py-24">
                    <Grid items container className="flex items-center">
                        <Grid item md={6} sm={12} className="model-slideshow testimonial-slider">
                            <Slider {...settings} className="row-slider py-24">                                                
                                {post.m_pictures.map(function(picture) {

                                    const SlideBg = [
                                        `${picture.model_gallery.publicURL}`
                                    ]                

                                    return (
                                        <Container key={picture.id}>
                                            <Grid container items>
                                                <Grid item md={12}>
                                                    <img src={SlideBg} />
                                                </Grid>
                                            </Grid>
                                        </Container>
                                    )                        
                                })}
                            </Slider>                                
                        </Grid>
                        <Grid item md={6} sm={12} className="model-description">
                            <h3>{post.m_gallery.txtgal_title}</h3>
                            <ReactMarkdown source={post.m_gallery.txtgal_descript} />
                            <div className="button-wrapper mt-8">
                                { post.m_gallery.txtgal_bluetitle ? <Link to={post.m_gallery.txtgal_bluelink}><Button variant="contained" color="primary"><span className="text-white">{post.m_gallery.txtgal_bluetitle}</span></Button></Link> : null }
                                { post.m_gallery.txtgal_greytitle ? <a href={post.modelguide.publicURL} target="_blank"><Button variant="contained" color="secondary"><span className="text-white">{post.m_gallery.txtgal_greytitle}</span></Button></a> : null }
                            </div>
                        </Grid>
                    </Grid>
                </Container>    
                {/* End Slider */}

                <Craftsmanship key={post.m_craftsmanship.id}
                    title={post.m_craftsmanship.craft_title}
                    description={post.m_craftsmanship.craft_description}
                />
    
                {/* Start Accordion */}
                <Container className="py-24">
                    <Grid items container className="flex items-center">
                        <Grid item md={6} sm={12} className="model-accordion">
                            <h3 className="mb-10">{post.slug} Model Specifications</h3>
                            <div className="about-accordion">
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <h5 className="blue">Colours</h5>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="icons-container flex justify-between">
                                        <div className="icon-img">
                                                <Tooltip title="Opal" arrow>
                                                    <img src={Opal} />
                                                </Tooltip>
                                            </div>
                                            <div className="icon-img">
                                                <Tooltip title="Terrazzo" arrow>
                                                    <img src={Terrazzo} />
                                                </Tooltip>
                                            </div>
                                            <div className="icon-img">
                                                <Tooltip title="Beachwood" arrow>
                                                    <img src={Beachwood} />
                                                </Tooltip>
                                            </div>
                                            <div className="icon-img">
                                                <Tooltip title="Alabaster" arrow>
                                                    <img src={Alabaster} />
                                                </Tooltip>
                                            </div>
                                            <div className="icon-img">
                                                <Tooltip title="Sierra Stone" arrow>
                                                    <img src={SierraStone} />
                                                </Tooltip>
                                            </div>
                                            <div className="icon-img">
                                                <Tooltip title="Onyx" arrow>
                                                    <img src={Onyx} />
                                                </Tooltip>
                                            </div>
                                        </div>
                                   </AccordionDetails>
                                </Accordion>      
                            </div>      
                            <div className="about-accordion">
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <h5 className="blue">Cabinetry</h5>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className="icons-container flex justify-between">
                                            <div className="icon-img">
                                                <Tooltip title="Bamboo" arrow>
                                                    <img src={Bamboo} />
                                                </Tooltip>
                                            </div>
                                            <div className="icon-img">
                                                <Tooltip title="Ebony" arrow>
                                                    <img src={Ebony} />
                                                </Tooltip>
                                            </div>
                                            <div className="icon-img">
                                                <Tooltip title="Walnut" arrow>
                                                    <img src={Walnut} />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>      
                            </div>      
                            {post.m_accordion.map(function(acc) {
                                return <div className="about-accordion" key={acc.id}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                            <h5 className="blue">{acc.accordion_title}</h5>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ReactMarkdown source={acc.accordion_description} />
                                        </AccordionDetails>
                                    </Accordion>            
                                </div>
                            })}        
                        </Grid>
                        <Grid item md={6} sm={12} className="model-description">
                            <img src={post.m_aerial.aerial_img.publicURL} alt={post.aerial_title} className="mx-auto" />
                            <img src={MadeCanada} className="mx-auto" />
                        </Grid>
                    </Grid>
                </Container>    
                {/* End Accordion */}

                {/* Start Other Models */}
                <div className="Grey md:text-center py-24 multi-column">
                    <h3 className="teal">View similar models in the series</h3>
                    <Container>    
                        <Grid Items Container className="flex flex-row flex-wrap justify-center">
                            {post.m_pillars.map(function(model) {

                                const Model = [
                                    `/models/${model.pillar_title}`.replace(" Series", "")
                                ]                

                                return <Grid item md={3} sm={6} key={model.id} className="flex justify-center flex-col items-center">
                                    <a href={Model}>
                                        <img src={model.pillar_img.publicURL} alt={model.pillar_title} />
                                        <h4 className="blue">{model.pillar_title}</h4>
                                    </a>
                                </Grid>     
                            })}
                        </Grid>
                    </Container>
                </div>
                {/* End Other Models */}

                <Outropiggy
                    outro={post.m_piggy.piggy_outro}
                />
      
        </Layout>
    )
}

export const query = graphql`
query ModelNumbers($slug: String!) {
    strapiModels(slug: {eq: $slug}) {
        slug
        m_header {
            id
            mh_title
            mh_subtitle
        }
        m_gallery {
            id
            txtgal_title
            txtgal_descript
            txtgal_bluetitle
            txtgal_bluelink
            txtgal_greytitle
            txtgal_greylink
        }
        m_pictures {
            id
            model_alt
            model_gallery {
                publicURL
            }
        }
        m_craftsmanship {
            id
            craft_title
            craft_description
        }
        m_accordion {
            id
            accordion_title
            accordion_description
        }
        m_aerial {
            id
            aerial_title
            aerial_img {
                publicURL
            }
        }
        m_pillars {
            id
            pillar_title
            pillar_img {
                publicURL
            }
        }
        m_piggy {
            id
            piggy_outro
        }
        modelguide {
            publicURL
          }      
    }    
}  
`